import featureDetection from './lib/feature-detection';
import cover from './lib/cover';
import security from './lib/security';

import clickable from './components/clickable';
import header from './components/header';
// import menus from './components/menus';
import mobileMenu from './components/mobile-menu';
import modal from './components/modal';
import scrollAnimate from './components/scroll-animate';
import search from './components/search';
import shareLinks from './components/share-links';
import youtubeBackground from './components/youtube-background';

import '../scss/frontend.scss';

(function () {
  let isInitialized = false;

  window.addEventListener('DOMContentLoaded', () => {
    if (!isInitialized) {
      isInitialized = true;
      init();
    }
  });

  const init = () => {
    featureDetection();
    cover();
    security();

    clickable();
    header();
    // menus();
    mobileMenu();
    modal();
    scrollAnimate();
    search();
    shareLinks();
    youtubeBackground();
  };
})();
