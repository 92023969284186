const featureDetection = () => {
  setHoverSupport();
  window.addEventListener('keydown', detectFirstTab);
};

export const hasHover = () => {
  return !window.matchMedia('(hover: none)').matches;
};

const setHoverSupport = () => {
  if (hasHover()) {
    document.documentElement.classList.add('has-hover');
  } else {
    document.documentElement.classList.add('no-hover');
  }
};

const detectFirstTab = (e) => {
  if (e.keyCode === 9) {
    document.documentElement.classList.add('has-user-tabbing');
    window.removeEventListener('keydown', detectFirstTab);
  }
};

export default featureDetection;
