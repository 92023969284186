function shareLinks() {
  const init = () => {
    const shareLinkEls = document.querySelectorAll('.js-share-link');

    [...shareLinkEls].forEach((el) => {
      el.addEventListener('click', (e) => {
        e.preventDefault();

        const url = el.getAttribute('href');

        if (!url) {
          return false;
        }

        openShareWindow(url);
      });
    });
  };

  const openShareWindow = (url) => {
    const left = (screen.width - 570) / 2;
    const top = (screen.height - 570) / 2;
    const params =
      'menubar=no,toolbar=no,status=no,width=570,height=570,top=' +
      top +
      ',left=' +
      left;

    window.open(url, 'NewWindow', params);
  };

  init();
}

export default shareLinks;
