function mobileMenu() {
  const init = () => {
    const el = document.querySelector('.js-mobile-menu');
    const menuItemEls = [...el.querySelectorAll('li.menu-item-has-children')];
    const firstContentLinkEl = document.querySelector('#site-content a');

    if (el === null) {
      return false;
    }

    setupMenuToggles();
    setupMenuLinksWithSubMenus(el);
    setupCloseButtons();
    // setupCloseArea();
    resetElements(el, menuItemEls, firstContentLinkEl);

    closeMenuOnFocusWithin(document.getElementById('site-content'));
  };

  const setupMenuToggles = () => {
    const toggleEls = document.querySelectorAll('.js-mobile-menu-toggle');
    toggleEls.forEach(addToggleEvent);
  };

  const setupCloseButtons = () => {
    const closeEls = document.querySelectorAll('.js-mobile-menu-close');
    if( !closeEls ){
      return;
    }
    closeEls.forEach(addCloseEvent);
  }

  const resetElements = (el, menuItemEls, firstContentLinkEl) => {
    if (el === null || menuItemEls === null || firstContentLinkEl === null)
      return false;

    firstContentLinkEl.addEventListener('focus', function () {
      menuItemEls.forEach((el) => {
        el.classList.remove('open');
        el.setAttribute('aria-expanded', 'false');
      });

      document.body.classList.remove('open-mobile-menu');
    });
  };

  const setupMenuLinksWithSubMenus = (el) => {
    // Event delegation for hidden elements
    el.addEventListener('click', (e) => {
      console.log(e.target);

      if (!e.target.classList.contains('js-mobile-menu-dropdown-toggle')) {
        return;
      }

      const parent = e.target.parentNode.parentNode;
      const subMenu = parent.querySelector('.sub-menu');

      console.log({
        parent: parent,
        submenu: subMenu,
      });

      if (!parent.classList.contains('is-open')) {
        e.preventDefault();
        parent.classList.add('is-open');
        parent.setAttribute('aria-expanded', 'true');
        subMenu.style.visibility = 'visible';
      } else {
        e.preventDefault();
        parent.classList.remove('is-open');
        parent.setAttribute('aria-expanded', 'false');

        setTimeout(() => {
          subMenu.style.visibility = 'hidden';
        }, 250);
      }
    });
  };

  const addToggleEvent = (el) => {
    el.addEventListener('click', (e) => {
      e.preventDefault();
      document.body.classList.toggle('has-open-mobile-menu');
    });
  };

  const addCloseEvent = (el) => {
    el.addEventListener('click', (e) => {
      e.preventDefault();
      document.body.classList.remove('has-open-mobile-menu');
    });
  };

  const closeMenuOnFocusWithin = (el) => {
    
    if( !el ){
      return;
    }

    el.addEventListener(
      'focusin',
      () => {
        document.body.classList.remove('has-open-mobile-menu');
      },
      { passive: true },
    );
  };

  init();
}

export default mobileMenu;
