import { isMobileMenuOpen } from '../lib/helpers.js';
import Headroom from 'headroom.js';

function header() {
  const init = () => {
    const el = document.querySelector('.js-header');

    if (el === null) {
      return false;
    }

    setupHeadroom(el);
  };

  const setupHeadroom = (el) => {
    const headroom = new Headroom(el, {
      offset: 110,
      tolerance: 5,
      onUnpin: function () {
        if (isMobileMenuOpen()) {
          this.elem.classList.remove(this.classes.unpinned);
          this.elem.classList.add(this.classes.pinned);
        } else {
          this.elem.classList.add(this.classes.unpinned);
          this.elem.classList.remove(this.classes.pinned);
        }
      },
    });

    headroom.init();
  };

  init();
}

export default header;
