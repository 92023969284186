const security = () => {
  const init = () => {
    removeTargetBlankReferrer();
  };

  /**
   * Adds rel="noopener noreferrer" to all links that open in a new window.
   *
   * Read more about the vulnerability from:
   * https://www.jitbit.com/alexblog/256-targetblank---the-most-underestimated-vulnerability-ever/
   */
  const removeTargetBlankReferrer = () => {
    const els = document.querySelectorAll('a');

    Array.from(els).forEach((el) => {
      const target = el.getAttribute('target');

      if (target === '_blank') {
        el.setAttribute('rel', 'noopener noreferrer');
      }
    });
  };

  init();
};

export default security;
