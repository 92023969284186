function clickable() {
  const init = () => {
    const els = document.querySelectorAll('.js-clickable');

    [...els].forEach((el) => {
      el.classList.add('wrapper-link');
      el.addEventListener('click', (e) => {
        const link = el.querySelector('a');
        if (!link) return;
        const url = link.getAttribute('href');
        if (!url) return;
        e.preventDefault();
        window.location.href = url;
      });
    });
  };

  init();
}

export default clickable;
